import {Col, Row, Form, Button} from "react-bootstrap";
import styles from "./calculoperdidas.module.css"
import React, {useContext, useState, useEffect} from "react";
import LangContext from "../../../../context/LangContext";
import DBContext from "../../../../context/DBContext";
import currencyAll from "../../../../data/currency.json";
import axios from "axios";
import {env} from "react-google-recaptcha/.eslintrc";
import {urlConfig} from "../../../../config/urlConfig";
import {FiDownload} from "react-icons/fi";
import {onlyNumbers} from "../../../../config/functions";

export const CalculoPerdidas = ({
                                    setClaimForm,
                                    claimForm,
                                    setDocuments,
                                    documents,
                                    filesClaim,
                                    setFilesClaim,
                                    handleDocuments,
                                    arrFile,
                                    setArrFile,
                                    imRsk,
                                }) => {
    const {traduccion} = useContext(LangContext);
    const {currency} = useContext(DBContext);
    const [currencyFrom, setCurrencyFrom] = useState(null);
    const [currencyTo, setCurrencyTo] = useState(null);
    const [amount, setAmount] = useState(0);
    const [amountConverted, setAmountConverted] = useState(0);
    const [notaCredito, setNotaCredito] = useState(claimForm?.documents?.calculation?.creditNote || []);
    const [preciosMercado, setPreciosMercado] = useState(claimForm?.documents?.calculation?.marketPrices || []);
    const [gastosExtra, setGastosExtra] = useState(claimForm?.documents?.calculation?.extraExpenses || []);
    const [resultadosVentas, setResultadosVentas] = useState(claimForm?.documents?.calculation?.salesResults || []);
    const {calculoPerdidas} = traduccion;
    const [optionToShow, setOptionToShow] = useState(claimForm?.lossTypeId ?? 1)
    const [arrInput1, setArrInput1] = useState([]);

    //const [arrInput1, setArrInput1] = useState(claimForm.lossTypeId === 1? claimForm?.documents?.calculation?.creditNote : claimForm?.documents?.calculation?.marketPrices || [])
    const [arrInput2, setArrInput2] = useState([]);
    const [optionCode, setOptionCode] = useState(claimForm?.lossTypeId ?? 1)
    const [optionSelected, setOptionSelected] = useState(calculoPerdidas[`opcion${optionCode}`])
    const [arrInputCurrency, setArrInputCurrency] = useState(calculoPerdidas[`inputs${optionCode}`] || [])
    const [calculatedValue, setCalculatedValue] = useState('')


    // const handleAddInput1 = () => {
    //     if (optionToShow === 1) {
    //         const newNotaCredito = [...notaCredito];
    //         newNotaCredito.push("");
    //         setNotaCredito(newNotaCredito);
    //         setArrInput1(newNotaCredito);
    //         setFilesClaim({
    //             ...filesClaim,
    //             calculation: {...filesClaim.calculation, creditNote: newNotaCredito},
    //         });
    //     }
    //     if (optionToShow === 2) {
    //         const newPreciosMercado = [...preciosMercado];
    //         newPreciosMercado.push("");
    //         setPreciosMercado(newPreciosMercado);
    //         setArrInput1(newPreciosMercado);
    //         setFilesClaim({
    //             ...filesClaim,
    //             calculation: {
    //                 ...filesClaim.calculation,
    //                 marketPrices: newPreciosMercado,
    //             },
    //         });
    //     }
    // };


    // const handleAddInput1 = () => {
    //     if (optionToShow === 1) {
    //         const newNotaCredito = [...notaCredito];
    //         newNotaCredito.push("");
    //         setNotaCredito(newNotaCredito);
    //         setArrInput1(newNotaCredito);
    //         setFilesClaim({
    //             ...filesClaim,
    //             calculation: {...filesClaim.calculation, creditNote: newNotaCredito},
    //         });
    //     }
    //     if (optionToShow === 2) {
    //         const newPreciosMercado = [...preciosMercado];
    //         newPreciosMercado.push("");
    //         setPreciosMercado(newPreciosMercado);
    //         setArrInput1(newPreciosMercado);
    //         setFilesClaim({
    //             ...filesClaim,
    //             calculation: {
    //                 ...filesClaim.calculation,
    //                 marketPrices: newPreciosMercado,
    //             },
    //         });
    //     }
    // };


    const handleAddInput1 = () => {
        if (optionToShow === 1) {
            const newNotaCredito = [...notaCredito, ""];
            setNotaCredito(newNotaCredito);
            setArrInput1(prevArr => [...prevArr, ...newNotaCredito]);
            setFilesClaim(prevFilesClaim => ({
                ...prevFilesClaim,
                calculation: {
                    ...prevFilesClaim.calculation,
                    creditNote: [...prevFilesClaim.calculation.creditNote, ""], // Agregar un espacio en blanco para el nuevo documento
                },
            }));
        }
        if (optionToShow === 2) {
            const newPreciosMercado = [...preciosMercado, ""];
            setPreciosMercado(newPreciosMercado);
            setArrInput1(prevArr => [...prevArr, ...newPreciosMercado]);
            setFilesClaim(prevFilesClaim => ({
                ...prevFilesClaim,
                calculation: {
                    ...prevFilesClaim.calculation,
                    marketPrices: [...prevFilesClaim.calculation.marketPrices, ""], // Agregar un espacio en blanco para el nuevo documento
                },
            }));
        }
    };


    const handleAddInput2 = () => {
        if (optionToShow === 1) {
            const newGastoExtra = [...gastosExtra];
            newGastoExtra.push("");
            setGastosExtra(newGastoExtra);
            setArrInput2(newGastoExtra);
            setFilesClaim({
                ...filesClaim,
                calculation: {
                    ...filesClaim.calculation,
                    extraExpenses: newGastoExtra,
                },
            });
        }
        if (optionToShow === 2) {
            const newResultadosVentas = [...resultadosVentas];
            newResultadosVentas.push("");
            setResultadosVentas(newResultadosVentas);
            setArrInput2(newResultadosVentas);
            setFilesClaim({
                ...filesClaim,
                calculation: {
                    ...filesClaim.calculation,
                    salesResults: newResultadosVentas,
                },
            });
        }
    };

    useEffect(() => {
        const claimsFormKeys = Object.keys(claimForm);
        if(claimsFormKeys.length > 0  && claimForm !== undefined){
            setArrInput1(claimForm.lossTypeId === 1
                ? claimForm?.documents?.calculation?.creditNote
                : claimForm?.documents?.calculation?.marketPrices || []
            );
        }
    }, [claimForm ,arrInput1])


    useEffect(() => {
        const claimsFormKeys = Object.keys(claimForm);
        if (claimsFormKeys.length > 0 && claimForm !== undefined) {
            setArrInput2(claimForm.lossTypeId === 1

                ? claimForm?.documents?.calculation?.extraExpenses
                : claimForm.documents?.calculation?.salesResults || []
            );
            //console.log('Que devuelve ArrInput1?', arrInput2);
        }

        //console.log("LLego con información nueva...:", claimForm);
        //console.log('Que devuelve claimForm?', claimForm);
    }, [claimForm, arrInput2])


    //Guardando el nombre mientras se crea el arreglo de files
    // useEffect(() => {
    //     for (const file of arrFile) {
    //         let nombre = file.name.split("_")[0];
    //         let indice = file.name.indexOf("_");
    //         let resto = file.name.slice(indice + 1);
    //         ////console.log({nombre,indice,resto})
    //
    //         setFilesClaim({
    //             ...filesClaim,
    //             [nombre]: resto
    //         })
    //     }
    // }, [arrFile]);

    // useEffect(() => {
    //     if (claimForm?.documents) {
    //         setClaimForm({
    //             ...claimForm,
    //             documents: filesClaim
    //         })
    //     }
    // }, [filesClaim]);
    //
    // useEffect(() => {
    //     ////console.log(claimForm)
    //     ////console.log({filesClaim})
    //     if (claimForm?.documents) {
    //         setClaimForm({
    //             ...claimForm,
    //             documents: filesClaim
    //
    //         })
    //     }
    // }, []);


    useEffect(() => {
        if (claimForm?.lossTypeId === 1) {
            claimForm.lossCalculation = [{amount: 0, amountId: 1, currency: 1},
                {amount: 0, amountId: 2, currency: 1},
                {amount: 0, amountId: 3, currency: 1},
                {amount: 0, amountId: 4, currency: 1},
                {amount: 0, amountId: 7, currency: 1}]
        }
        if (claimForm?.lossTypeId === 2) {
            claimForm.lossCalculation = [{amount: 0, amountId: 5, currency: 1},
                {amount: 0, amountId: 6, currency: 1},
                {amount: 0, amountId: 7, currency: 1}]
        }
    }, [optionCode]);

    const claimUpdate = (claimLoss) => {
        setClaimForm({...claimForm, lossCalculation: claimLoss})
    }

    const handleRemoveInput1 = (e) => {
        let ultimo = "";
        if (optionToShow === 1) {
            const newNotaCredito = [...notaCredito]

            let idx = newNotaCredito.length - 1;
            ultimo = newNotaCredito.pop();
            setNotaCredito(newNotaCredito);
            setArrInput1(newNotaCredito);
            setFilesClaim({...filesClaim, calculation: {...filesClaim.calculation, creditNote: newNotaCredito}})
        }
        if (optionToShow === 2) {
            const newPreciosMercado = [...preciosMercado];

            let idx = newPreciosMercado.length - 1;
            ultimo = newPreciosMercado.pop();
            setPreciosMercado(newPreciosMercado);
            setArrInput1(newPreciosMercado);
            setFilesClaim({...filesClaim, calculation: {...filesClaim.calculation, marketPrices: newPreciosMercado}})
        }
        // //borra del arreglo de files con el nombre que se guardó
        const arrFiles = arrFile.filter((file) => file.name !== ultimo);
        setArrFile(arrFiles);
    }

    const handleRemoveInput2 = (index) => {
        let ultimo = "";
        if (optionToShow === 1) {
            const newGastoExtra = [...gastosExtra]

            let idx = newGastoExtra.length - 1;
            ultimo = newGastoExtra.pop();
            setGastosExtra(newGastoExtra);
            setArrInput2(newGastoExtra);
            setFilesClaim({...filesClaim, calculation: {...filesClaim.calculation, extraExpenses: newGastoExtra}})
        }
        if (optionToShow === 2) {
            const newResultadosVentas = [...resultadosVentas]

            let idx = newResultadosVentas.length - 1;
            ////console.log(newResultadosVentas.pop())
            ultimo = newResultadosVentas.pop();
            setResultadosVentas(newResultadosVentas);
            setArrInput2(newResultadosVentas);
            setFilesClaim({...filesClaim, calculation: {...filesClaim.calculation, salesResults: newResultadosVentas}})
        }
        // //borra del arreglo de files con el nombre que se guardó
        const arrFiles = arrFile.filter((file) => file.name !== ultimo);
        setArrFile(arrFiles);
    }


    const handleSelectOption = (e) => {
        const optionNumber = typeof e === 'number' ? e : parseInt(e.target.value);
        if (e.target.name === 'opcionesCalculo') {
            setClaimForm({...claimForm, lossTypeId: optionNumber})
        }
        const option = optionNumber === 2 ? calculoPerdidas.opcion2 : calculoPerdidas.opcion1;

        setCalculatedValue('');

        setOptionSelected(option);
        setOptionToShow(optionNumber);
        setOptionCode(optionNumber === 1 ? 1 : 2);


        setArrInputCurrency(optionNumber === 2 ? calculoPerdidas?.inputs2 : calculoPerdidas?.inputs1);

        setArrInput1(optionNumber === 2 ? preciosMercado : notaCredito);
        setArrInput2(optionNumber === 2 ? resultadosVentas : gastosExtra);

        //ACTUALIZAR EL UPDATE DEL SELECT
        // claimUpdate(newClaim);
    }

    const handleChangeInput = (e) => {
        const index = parseInt(e.target.id);
        const inputName = e.target.name;

        // Filtrar solo números, puntos y comas
        const value = e.target.value.replace(/[^0-9.,]/g, '').replace(',', '.'); // Convertir comas a puntos pero mantenerlo como string
        const amountId = arrInputCurrency[index].amountId;

        const newClaimLoss = claimForm.lossCalculation;

        if (inputName === 'inputValue') {
            // Buscamos el elemento actual en newClaimLoss o lo creamos si no existe
            const existingEntry = newClaimLoss.find(curr => curr.amountId === amountId);
            if (existingEntry) {
                existingEntry.amount = value; // Mantener el valor como string
            } else {
                newClaimLoss.push({
                    amountId: amountId,
                    currency: '',
                    amount: value, // Mantener el valor como string
                });
            }
        }

        if (inputName === 'currency') {
            const existingEntry = newClaimLoss.find(curr => curr.amountId === amountId);
            if (existingEntry) {
                existingEntry.currency = parseInt(value);
            } else {
                newClaimLoss.push({
                    amountId: amountId,
                    currency: parseInt(value),
                    amount: ''
                });
            }
        }

        claimUpdate(newClaimLoss);
    };


    // useEffect(() => {
    //     if (claimForm?.documents && claimForm?.documents?.length > 0) {
    //         setClaimForm({
    //             ...claimForm, documents: {
    //                 ...claimForm.documents,
    //                 calculation: {
    //                     ...claimForm.documents.calculation,
    //                     creditNote: notaCredito,
    //                     marketPrices: preciosMercado,
    //                     extraExpenses: gastosExtra,
    //                     salesResults: resultadosVentas
    //                 }
    //             }
    //         })
    //     }
    // }, [notaCredito, preciosMercado, gastosExtra, resultadosVentas]);

    const handleConv = (e) => {
        axios.get(`https://v6.exchangerate-api.com/v6/${process.env.REACT_APP_CURRENCY_KEY}/pair/${currencyFrom}/${currencyTo}/${amount}`)
            .then(res => {
                setAmountConverted(res.data.conversion_result);

            }).catch(err => {
            //console.log(err)
        })
    }


    const downloadFile = (bytes, fileName) => {
        const blob = new Blob([bytes], {type: "application/pdf"});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click()
    }
    const descargaUno = (filename) => {
        axios({
            url: `${urlConfig.allUrl}:9004/api/v1/files/download?file=${filename}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        }).then((response) => {
            downloadFile(response.data, filename.split("_")[0])
        }).catch((error) => {
            //console.log(error);
        });
    }


    //console.log({notaCredito})
    //console.log({preciosMercado})
    //console.log({gastosExtra})
    //console.log({resultadosVentas})
    //console.log({arrInput1})
    //console.log({arrInput2})
    return (
        <>
            <div className={styles.selects} style={{marginRight: '1rem', marginTop: '1rem', marginLeft: '3rem'}}>
                <Form.Group controlId="option">
                    <Form.Select disabled={!imRsk}
                                 className={imRsk ? styles.selects_input : styles.selects_input_disabled}
                                 name="opcionesCalculo" onChange={handleSelectOption}>
                        <option name="opcion1" key={1} value={1}
                                selected={optionCode === 1}>{calculoPerdidas.opcion1}</option>
                        <option name="opcion2" key={2} value={2}
                                selected={optionCode === 2}>{calculoPerdidas.opcion2}</option>
                    </Form.Select>
                </Form.Group>
            </div>
            <div className={styles.container}>
                <div className={styles.title_main}>{optionSelected}</div>
                <div>
                    <Row style={{margin: "0.2rem", padding: '1rem'}}>
                        <Col>
                            {/*---------- ARCHIVOS CREDIT NOTE ------------*/}
                            <div className={styles.container2}>
                                <div className={styles.selects_file}>

                                    <div
                                        className="pe-2">{optionToShow === 1 ? calculoPerdidas.notaCredito : calculoPerdidas.precioMercado}</div>
                                    {
                                        arrInput1.map((nota, index) => {
                                            return (
                                                <div style={{display: 'flex', 'flexDirection': 'row'}} key={index}>
                                                    <div style={{marginBottom: '0'}}>
                                                        {arrInput1[index] &&
                                                            <div className={styles.file_name}>{arrInput1[index]}</div>}
                                                        <Form.Group className="d-flex">
                                                            <Form.Control disabled={!imRsk} key={index}
                                                                          name={optionToShow === 1 ? `creditnotes${index}` : `marketprices${index}`}
                                                                          type="file"
                                                                          className={!imRsk ? styles.selects_input_disabled : arrInput1[index] ? styles.file_input2 : styles.file_input}
                                                                          onChange={handleDocuments}/>
                                                            {(/^CREDITNOTES_/.test(arrInput1[index]) || /^MARKETPRICES/.test(arrInput1[index])) &&
                                                                <div className={styles.button_des}
                                                                     onClick={() => descargaUno(arrInput1[index])}>
                                                                    <FiDownload/></div>}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div>{traduccion.documentos?.soloArchivos}</div>
                                    {imRsk &&
                                        <div className={styles.buttonadd} name="addnotacredito"
                                             onClick={handleAddInput1}>
                                            <span className={styles.addicon} style={{padding: '0 1rem 0 0'}}></span>
                                            <span style={{
                                                margin: '.3rem',
                                                color: "black"
                                            }}>{calculoPerdidas.agregarNuevo}</span>
                                        </div>
                                    }
                                    {/*<br></br>*/}
                                    {
                                        (arrInput1?.length > 0 && imRsk) &&
                                        <div className={styles.buttonremove} onClick={handleRemoveInput1}>
                                            <span className={styles.removeicon}
                                                  style={{padding: '0 1rem 0 0'}}></span><span
                                            style={{margin: '.3rem', color: "black"}}>Remover Documento</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={styles.container2}>
                                <div className={styles.selects_file}>
                                    <div
                                        className="pe-2">{optionToShow === 1 ? calculoPerdidas.gastosExtra : calculoPerdidas.resultadoVentas}</div>
                                    {
                                        arrInput2.map((gasto, index) => {
                                            return (
                                                <div style={{display: 'flex', 'flexDirection': 'row'}} key={index}>
                                                    <div style={{marginBottom: '0'}}>
                                                        {arrInput2[index] &&
                                                            <div className={styles.file_name}>{arrInput2[index]}</div>}

                                                        <Form.Group controlId="expenses" className="d-flex">
                                                            <Form.Control disabled={!imRsk} key={index}
                                                                          name={optionToShow === 1 ? `extraexpenses${index}` : `salesresults${index}`}
                                                                          type="file"
                                                                          className={!imRsk ? styles.selects_input_disabled : arrInput2[index] ? styles.file_input2 : styles.file_input}
                                                                          onChange={handleDocuments}/>
                                                            {(/^EXTRAEXPENSES_/.test(arrInput2[index]) || /^SALESRESULT/.test(arrInput2[index])) &&
                                                                <div className={styles.button_des}
                                                                     onClick={() => descargaUno(arrInput2[index])}>
                                                                    <FiDownload/></div>}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div>{traduccion.documentos?.soloArchivos}</div>
                                    {imRsk &&
                                        <div className={styles.buttonadd} name="addgastrosextra"
                                             onClick={handleAddInput2}>
                                            <span className={styles.addicon} style={{padding: '0 1rem 0 0'}}></span>
                                            <span>{calculoPerdidas.agregarNuevo}</span>
                                        </div>
                                    }
                                    {
                                        (arrInput2?.length > 0 && imRsk) &&
                                        <div className={styles.buttonremove} onClick={handleRemoveInput2}>
                                            <span className={styles.removeicon}
                                                  style={{padding: '0 1rem 0 0'}}></span><span
                                            style={{margin: '.3rem', color: "black"}}>Remover Documento</span>
                                        </div>
                                    }
                                </div>
                            </div>

                        </Col>


                        <Col>
                            {arrInputCurrency?.map((aInput, index) => {
                                if (index === 0) {
                                    index = "0"
                                }
                                // //console.log("aInput",aInput)
                                // //console.log(claimForm?.lossCalculation)
                                return (
                                    <div className={styles.container2} key={index}>
                                        <div className={styles.titles_p}>{aInput.name}</div>
                                        <div className={styles.selects}>
                                            {/*MONEDAS*/}
                                            <Form.Group>
                                                <Form.Select disabled={!imRsk} onChange={handleChangeInput} id={index}
                                                             name="currency"
                                                             className={imRsk ? styles.selects_input : styles.selects_input_disabled}>
                                                    <option value="">---</option>
                                                    {
                                                        currency.map((currency) => (
                                                            <option key={currency.id}
                                                                    value={currency.id}
                                                                    selected={(claimForm?.lossCalculation != undefined || claimForm?.lossCalculation != null) ?
                                                                        claimForm?.lossCalculation.find(e => e.amountId === aInput.amountId)?.currency == currency.id : ""}>
                                                                {currency?.description}</option>))
                                                    }
                                                </Form.Select>

                                            </Form.Group>
                                            {/*INPUT*/}
                                            <Form.Group style={{margin: '0'}}>
                                                <Form.Control disabled={!imRsk} key={index} id={index} type="text"
                                                              placeholder="$" onChange={handleChangeInput}
                                                              onInput={onlyNumbers}
                                                              className={imRsk ? styles.selects_input : styles.selects_input_disabled}
                                                              name="inputValue"
                                                              value={//aInput.id === "claimAmount" ? calculatedValue :
                                                                  ((claimForm?.lossCalculation != undefined || claimForm?.lossCalculation != null) ? claimForm?.lossCalculation.find(e => e.amountId === aInput.amountId)?.amount : '')}/>
                                            </Form.Group>
                                        </div>

                                        {/*INPUT SUMA TOTAL*/}
                                        <div className={styles.selects}>
                                            {aInput.name2 ?
                                                <div style={{
                                                    marginLeft: '1rem',
                                                    marginTop: '0',
                                                    display: 'flex',
                                                    'flexDirection': 'row'
                                                }}>
                                                    <div style={{marginTop: '0.5rem'}}
                                                         className="pe-2">{aInput.name2}</div>
                                                    <Form.Group>
                                                        <Form.Control disabled={!imRsk} key={index} id={index}
                                                                      type="text" placeholder="$"
                                                                      onChange={handleChangeInput}
                                                                      onInput={onlyNumbers}
                                                                      className={imRsk ? styles.selects_input : styles.selects_input_disabled}
                                                                      name="inputValue2"/>
                                                    </Form.Group></div>
                                                : null
                                            }

                                        </div>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                    {imRsk &&
                        <div className={styles.container2}>
                            <div className={styles.titles_p}>{calculoPerdidas.intercambioMoneda}</div>
                            <div className={styles.selects}
                                 style={{display: 'flex', 'flexDirection': 'row', marginRight: '1rem'}}>
                                <div className={styles.inputIntercambios}>
                                    <Form.Group style={{marginRight: '1rem'}} controlId="moneda1">
                                        <Form.Select disabled={!imRsk}
                                                     className={imRsk ? styles.selects_input : styles.selects_input_disabled}
                                                     onChange={(e) => setCurrencyFrom(e.target.value)}>
                                            <option value="">---</option>
                                            {
                                                // currency.map((currency) => (
                                                //     <option key={currency.id}
                                                //             value={currency.id}>{currency?.description}</option>))
                                                Object.entries(currencyAll).map(([key, value]) => (
                                                    <option key={key}
                                                            value={key}>{value.code}</option>
                                                ))
                                            }

                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group controlId="from">
                                        <Form.Control className={styles.selects_input}
                                                      onChange={(e) => setAmount(e.target.value)} value={amount}
                                                      type="text" placeholder="$" name="from" onInput={onlyNumbers}/>
                                    </Form.Group>
                                </div>

                                <div className={styles.titles_p}
                                     style={{marginTop: '0.5rem'}}>{calculoPerdidas.monedaA}</div>
                                <div className={styles.inputIntercambios}>
                                    <Form.Group style={{marginRight: '1rem'}} controlId="moneda2">
                                        <Form.Select disabled={!imRsk} onChange={(e) => setCurrencyTo(e.target.value)}
                                                     className={imRsk ? styles.selects_input : styles.selects_input_disabled}>
                                            <option value="">---</option>
                                            {
                                                // currency.map((currency) => (
                                                //     <option key={currency.id}
                                                //             value={currency.id}>{currency?.description}</option>))
                                                Object.entries(currencyAll).map(([key, value]) => (
                                                    <option key={key}
                                                            value={key}>{value.code}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>

                                    {/*<Form.Group controlId="to">*/}
                                    {/*      /!*<Form.Control className={styles.selects_input} type="text" placeholder="$"  name="to" onInput={onlyNumbers} />*!/*/}
                                    {/*    */}
                                    {/* </Form.Group>*/}
                                    <div style={{
                                        margin: '.3rem 0 1.5rem .5rem',
                                        padding: 4,
                                        alignSelf: 'center',
                                        border: '2px solid #bbb',
                                        borderRadius: '5px',
                                        backgroundColor: '#f1f3fe',
                                        minWidth: '15ch'
                                    }}> $ {amountConverted}</div>

                                </div>
                                <Button style={{margin: '.3rem 0 1.5rem .5rem', padding: '0 2rem'}} variant="secondary"
                                        onClick={handleConv}>{traduccion?.lossCalculation?.conversion}</Button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
